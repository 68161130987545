import React, { useState } from 'react';
import Snackbar from '~/components/Commons/Snackbar/Snackbar';
import { NotificationLevels } from '~/context/Notifications';
import notificationsStyle from './notificationsStyle';

type NotificationProps = {
  message: string | object;
  place: 'tl' | 'tr' | 'tc' | 'br' | 'bl' | 'bc';
  show: boolean;
  type: string;
  level: NotificationLevels;
  onClose?: () => void;
};

const Notification = ({ message, place, show, type, level, onClose }: NotificationProps) => {
  const [close, setClose] = useState(false);

  const cleanupTimeouts = () => {
    let id = window.setTimeout(() => null, 0);
    while (id) {
      id -= 1;
      window.clearTimeout(id);
    }
  };

  const handleNotificationClose = () => {
    setClose(false);
    if (onClose) {
      cleanupTimeouts();
      onClose();
    }
  };

  return (
    <>
      <Snackbar
        sx={{
          '& .MuiSnackbarContent-root': notificationsStyle.snackbarRoot,
        }}
        place={place}
        color={level}
        message={message}
        open={show && !close}
        closeNotification={handleNotificationClose}
        close
        TransitionProps={{
          timeout: {
            enter: 300,
            exit: 500,
          },
          easing: {
            enter: 'cubic-bezier(.37, 0, 0.63, 1)',
            exit: 'cubic-bezier(0.76, 0, 0.24, 1)',
          },
        }}
      />
    </>
  );
};

export default Notification;
