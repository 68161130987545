/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';
import * as Sentry from '@sentry/react';

// @mui/material components
import Hidden from '@mui/material/Hidden';

// core components
import { ReactComponent as ReleaseNotes } from '~/release.md';
import useSession from '~/hooks/useSession/useSession';
import { ClientsService } from '~/services/ClientsService';
import adminNavbarLinksStyle from '~/components/Commons/Navbars/adminNavbarLinksStyle';
import UserDropdownMenu from '~/components/Commons/UserDropdownMenu';
import useGetClientSessionsQuery from '~/hooks/queries/useGetClientSessionsQuery';
import useWindowSize from '~/hooks/useWindowSize';
import { Box } from '@mui/material';
import dialog from '../ActionConfirmDialog/useDialog';

import packagejson from '../../../../package.json';
import DataTable from '../DataTable/DataTable';

const initialState = { isNewCookie: false };

const IS_NEW_COOKIE = 'isNewCookie';

const reducer = (state, action) => {
  switch (action.type) {
    case IS_NEW_COOKIE:
      return {
        ...state,
        isNewCookie: action.payload,
      };
    default:
      return state;
  }
};
/**
 * @type {import('material-react-table').MRT_ColumnDef[]} columns
 */
const columns = [
  {
    header: 'Country',
    accessorKey: 'country_name',
  },
  {
    header: 'IP Address',
    accessorKey: 'ipaddress',
  },
  {
    header: 'Timestamp',
    accessorKey: 'stamp',
  },
  {
    header: 'Status',
    accessorKey: 'status',
  },
];

function AdminNavbarLinks({ setNotification, closeNotification }) {
  const { logout } = useSession();
  const [major, minor] = packagejson.version.split('.');
  const majorMinor = `${major}.${minor}`;
  const [cookies, setCookies, removeCookies] = useCookies(['version']);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isNewCookie } = state;
  const { innerWidth } = useWindowSize();

  const { data = {}, isInitialLoading: isLoading } = useGetClientSessionsQuery();

  const handleOpenWhatsNew = useCallback(async () => {
    try {
      dialog({
        title: "What's New on MyDianomi",
        body: <ReleaseNotes />,
        cancelText: 'Close',
        width: 'md',
        onClose: dialog.close,
        onOpen: null,
        showCloseButton: true,
      });
      closeNotification();
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [closeNotification]);

  const handleWhatsNewClick = (e) => {
    e.preventDefault();
    handleOpenWhatsNew();
  };

  const handleCloseAllSessions = () => {
    ClientsService.closeClientSessions().then((result) => {
      if (result.logged_out && result.logged_out === 1) {
        logout(1000);
      }
      dialog.close();
    });
  };

  const handleSessionDataActionDialogOpen = (e) => {
    e.preventDefault();
    const sessionsData = (
      <Box sx={adminNavbarLinksStyle.sessionTableWrapper}>
        <DataTable
          data={data.sessions}
          columns={columns}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          muiTableBodyRowProps={{ hover: false }}
        />
      </Box>
    );
    dialog({
      title: 'Active Sessions',
      body: data.sessions.length ? sessionsData : <p>Session data unavailable</p>,
      acceptText: 'Close All Sessions',
      width: 'sm',
      onClose: dialog.close,
      onAccept: handleCloseAllSessions,
    });
  };

  useEffect(() => {
    if (!cookies.version || cookies.version !== majorMinor) {
      removeCookies('version');
      dispatch({ type: IS_NEW_COOKIE, payload: true });
    }
  }, [cookies.version, majorMinor, removeCookies]);

  useEffect(() => {
    if (isNewCookie) {
      const message = (
        <Box sx={adminNavbarLinksStyle.whatsNewNotification}>
          <div style={{ whiteSpace: 'nowrap' }}>New update to the MyDianomi dashboard!</div>
          <a
            role="button"
            tabIndex="0"
            onClick={handleOpenWhatsNew}
            style={adminNavbarLinksStyle.clickHere}
          >
            {` Click here `}
          </a>
          to view the update.
        </Box>
      );
      setNotification(true, 'primary', message);
      dispatch({ type: IS_NEW_COOKIE, payload: false });
    }
  }, [handleOpenWhatsNew, isNewCookie, setNotification]);

  useEffect(() => {
    if (isNewCookie) {
      const date = new Date();
      setCookies('version', majorMinor, {
        path: '/',
        expires: new Date(date.setMonth(date.getMonth() + 3)),
      });
    }
  }, [isNewCookie, majorMinor, setCookies]);

  const sessionsCount = data.sessions ? data.sessions.length : 0;

  return isLoading ? null : (
    <Box sx={adminNavbarLinksStyle.navbarWrapper}>
      <Box sx={(theme) => adminNavbarLinksStyle.managerClasses(theme)}>
        <Hidden smDown>
          {innerWidth > 960 && (
            <Box sx={adminNavbarLinksStyle.sessionsWrapper}>
              <div>
                <div role="button" tabIndex={-1} onClick={handleSessionDataActionDialogOpen}>
                  <Box
                    sx={[
                      sessionsCount > 1
                        ? adminNavbarLinksStyle.activeSessionsWarn
                        : adminNavbarLinksStyle.activeSessions,
                      adminNavbarLinksStyle.linkText,
                    ]}
                  >
                    {`Active sessions: ${sessionsCount}`}
                  </Box>
                </div>
              </div>
              <div>
                <div role="button" tabIndex={-1} onClick={handleWhatsNewClick}>
                  <Box sx={adminNavbarLinksStyle.linkText}>{`v${packagejson.version}`}</Box>
                </div>
              </div>
            </Box>
          )}
        </Hidden>
      </Box>
      <UserDropdownMenu setNotification={setNotification} />
    </Box>
  );
}

AdminNavbarLinks.propTypes = {
  setNotification: PropTypes.func.isRequired,
  closeNotification: PropTypes.func.isRequired,
};
AdminNavbarLinks.defaultProps = {};

export default AdminNavbarLinks;
